import apiClient from "../../axios/axios";

export async function update_lifecycle_details(data) {
  try {
    const endpoint = `/updateLifeCycleDetails`;
    const res = await apiClient.patch(endpoint, data);
    console.log("RES STATUS: ", res);
    return res.status;
  } catch (err) {
    console.log("Err on lifecycle store patcher");
  }
}
