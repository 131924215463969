<template>
  <main
    class="base-checkbox-wrapper"
    :class="{ 'border-checked': checkState }"
    @click="toggleCheck"
  >
    <input type="checkbox" ref="baseChkBox" :checked="checkState" />
    <div class="flex-column">
      <p class="-xstb" :class="{ 'label-checked': checkState }">
        {{ getLabel }}
      </p>
      <p class="-xstr">{{ getTooltip }}</p>
    </div>
  </main>
</template>

<script>
export default {
  props: ["config"],
  emits: ["getCheckState"],
  data() {
    return {
      checkState: false,
    };
  },
  methods: {
    toggleCheck() {
      this.checkState = !this.checkState;
    },
  },
  computed: {
    getLabel() {
      return this.config.label;
    },
    getTooltip() {
      return this.config.tooltip;
    },
  },
  watch: {
    checkState: function () {
      this.$emit("getCheckState", this.checkState);
    },
  },
};
</script>

<style scoped>
.base-checkbox-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  border: 1px solid var(--gray6);
  border-radius: 4px;
  padding: 8px;
}
input[type="checkbox"],
.base-checkbox-wrapper:hover {
  cursor: pointer;
}
input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
.base-checkbox-wrapper:hover {
  background: var(--secondary-highlight);
}
.label-checked {
  color: var(--info);
}
.border-checked {
  border: 1px solid var(--secondary-highlight);
}
</style>
