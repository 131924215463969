<template>
  <main class="base-stages-wrapper">
    <div
      v-for="stage in stages"
      :key="stage"
      :class="[!stage.isSelected ? 'stage' : 'stage-selected']"
      @click="setSelected(stage)"
      :title="stage.stage_name"
    >
      <p
        :style="`color: ${stage.color_code}`"
        class="-ntb"
        :class="{ 'not-selected': !stage.isSelected }"
      >
        {{ stage.stage_value }}
      </p>
    </div>
  </main>
</template>

<script>
export default {
  props: [],
  emits: [],
  components: {},
  data() {
    return { stages: null, selectedStages: [] };
  },
  created() {
    this.stages = JSON.parse(JSON.stringify(this.$store.getters.stages));
  },
  methods: {
    setSelected(stage) {
      stage.isSelected = !stage.isSelected;
      stage.isSelected
        ? this.selectedStages.push(stage.frame_stage_id)
        : (this.selectedStages = this.selectedStages.filter(
            (item) => item !== stage.frame_stage_id
          ));
    },
  },
  watch: {
    "selectedStages.length": function () {
      console.log(this.selectedStages.length);
      //dont forget to sort and emit the array of frame_stage_id's
    },
  },
};
</script>

<style scoped>
.base-stages-wrapper {
  --stage-edge: 32px;
  width: 320px;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--stage-edge));
  gap: 8px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid var(--gray6); */
  border-radius: 4px;
}
.stage,
.stage-selected {
  border-radius: 8px;
  width: var(--stage-edge);
  height: var(--stage-edge);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 250ms, background 250ms;
}
.stage:active,
.stage-selected:active {
  scale: 0.95;
}
.stage {
  background: rgba(43, 43, 71, 0.219);
}
.stage-selected {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.2) 0px 2px 4px -1px;
}
.stage > p {
  color: var(--white1) !important;
  transition: 200ms;
}
.stage:hover > p {
  color: var(--primary) !important;
}
</style>
