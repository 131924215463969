<template>
  <main class="form-wrapper">
    <h1 class="-xltb column-span-4" style="white-space: none">
      Add New Element
    </h1>
    <p class="-xstr column-span-4">
      You are about to add the following elements:
    </p>
    <hr class="column-span-4" />
    <div class="base-checkbox-container">
      <BaseCheckbox
        :config="BASE_CHKBOX_CONFIG"
        @getCheckState="getCheckState"
      />
    </div>

    <div
      class="column-span-4 elements-summary"
      v-for="({ id, code, name }, index) in getModalItems"
      :key="id"
    >
      <section class="flex-column gap4">
        <div class="relation-header">
          <p class="-stb">{{ code }}</p>
          <p class="-str">{{ name }}</p>
        </div>

        <div v-show="baseChkBoxState" class="flex-column gap12">
          <section
            class="relation-wrapper"
            v-for="item in relationRows[index]"
            :key="item"
          >
            <div class="search-dropdown-container">
              <SearchDropdown
                :placeholder="MULTISEARCH_DPDOWN_CONFIG.placeholder"
                :titles="MULTISEARCH_DPDOWN_CONFIG.items"
              />
            </div>
            <div class="stages-container" v-if="code.length > 8">
              <BaseStages />
            </div>
            <div class="nickname-container">
              <input
                class="-xstr"
                type="text"
                placeholder="Set nickname"
                title="Ex: Modeler"
              />
            </div>
          </section>

          <section class="relations-control-wrapper" v-if="code.length > 8">
            <div
              class="add-sub-wrappers"
              title="Remove last relation"
              @click="decRelationRows(index)"
              v-show="relationRows[index] > 1"
            >
              <p class="-ntb">-</p>
            </div>
            <div
              class="add-sub-wrappers"
              title="Add new relation"
              @click="incRelationRows(index)"
              v-show="relationRows[index] < 4"
            >
              <p class="-ntb">+</p>
            </div>
          </section>
        </div>
      </section>
      <hr class="column-span-4" />
    </div>

    <section class="btns-wrapper">
      <BaseButton :state="'primary'" :title="'Add Elements'" />
    </section>
  </main>
</template>

<script>
import BaseButton from "../../../../utils/BaseButton.vue";
import BaseCheckbox from "../../../../utils/BaseCheckbox.vue";
import SearchDropdown from "../../../../utils/SearchDropdown.vue";
import BaseStages from "../../../../utils/BaseStages.vue";
import { mapGetters } from "vuex";

export default {
  components: { BaseButton, BaseCheckbox, SearchDropdown, BaseStages },
  data() {
    return {
      BASE_CHKBOX_CONFIG: {
        label: "Relate to Agent",
        tooltip: "Select to define element-agent relationship.",
      },
      MULTISEARCH_DPDOWN_CONFIG: {
        placeholder: "Select an agent...",
        items: [
          "Architect",
          "Building services engineer",
          "Civil engineer",
          "Client",
          "Cost consultant",
          "Lead designer",
          "Project Lead",
          "Structural engineer",
          "Construction lead",
          "Contract administrator",
          "Health and safety adviser",
          "Landscape architect",
          "Lift engineer",
        ],
      },
      baseChkBoxState: false,
      relationRows: null,
    };
  },
  created() {
    this.setRelationRows();
  },
  methods: {
    setRelationRows() {
      //for each item inside the modal, add value of 1 to relationRows array
      this.relationRows = this.getModalItems.map(() => 1);
    },
    getCheckState(val) {
      this.baseChkBoxState = val;
    },
    decRelationRows(index) {
      this.relationRows[index]--;
    },
    incRelationRows(index) {
      this.relationRows[index]++;
    },
  },
  computed: {
    ...mapGetters(["scopeModal"]),
    getModalItems() {
      return this.$store.state.scopeModal.item;
    },
  },
};
</script>

<style scoped>
* {
  --content-height: 300px;
}
.form-wrapper {
  width: 100%;
  /* height: auto; */
  max-height: 800px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-inline: 32px;
  padding-block: 16px;
  gap: 12px;
  background: var(--white1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.column-span-4 {
  grid-column: span 4;
}
.column-span-3 {
  grid-column: span 3;
}
.column-span-2 {
  grid-column: span 2;
}
.column-span-1 {
  grid-column: span 1;
}
.elements-summary {
  display: flex;
  flex-flow: column;
  gap: 16;
  position: relative;
}
.btns-wrapper {
  /* border: 1px solid red; */
  grid-column: span 4;
  display: flex;
  justify-content: flex-end;
}
.btns-wrapper > main {
  width: auto;
}
hr {
  border: none;
  border-top: 1px solid var(--gray6);
  margin-top: 1rem;
}
.gap12 {
  gap: 12px;
}
.gap16 {
  gap: 16px;
}
.base-checkbox-container {
  width: 320px;
}
.relation-header {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}
.relation-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.search-dropdown-container {
  position: relative;
  min-width: 220px;
  max-width: 220px;
}
.stages-container {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
}
.nickname-container {
  min-width: 160px;
  max-width: 160px;
}
input[type="text"] {
  border: 1px solid var(--gray5);
  border-radius: 2px;
  padding: 8px;
  outline: none;
  max-height: 38px;
  min-height: 38px;
}
input[type="text"]::placeholder {
  color: var(--gray1);
}
.relations-control-wrapper {
  display: flex;
  gap: 8px;
}
.add-sub-wrappers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid var(--gray5);
  border-radius: 2px;
  cursor: pointer;
  color: var(--gray4);
  transition: 240ms;
}
.add-sub-wrappers:hover {
  color: var(--info);
  border-color: var(--info);
}
/**EFFECTS **/
input[type="text"]:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
@media screen and (max-width: 1023px) {
  .relation-wrapper {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }
  .relation-wrapper input {
    width: 220px;
  }
}
@media screen and (max-width: 719px) {
  .column-span-4 {
    grid-column: span 4;
  }
  .column-span-3 {
    grid-column: span 4;
  }
  .column-span-2 {
    grid-column: span 4;
  }
  .column-span-1 {
    grid-column: span 4;
  }
  .relation-header {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}
</style>
