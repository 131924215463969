<template>
  <main class="currencies-list-container">
    <select class="-xstb">
      <option selected disabled hidden>
        {{ selectedCurrency.symbol }} {{ selectedCurrency.code }}
      </option>
      <option v-for="currency in currencies" :key="currency" class="-xstb">
        {{ currency.symbol }} ({{ currency.code }})
      </option>
    </select>
  </main>
</template>

<script>
export default {
  data() {
    return {
      selectedCurrency: { code: "EUR", name: "Euro", symbol: "€" },
      currencies: [
        { code: "EUR", name: "Euro", symbol: "€" },
        { code: "USD", name: "United States Dollar", symbol: "$" },
        { code: "GBP", name: "British Pound", symbol: "£" },
      ],
    };
  },
};
</script>

<style scoped>
.currencies-list-container {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
select {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding-left: 4px;
  position: relative;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  border-left: 1px solid var(--gray5);
}
select:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
</style>