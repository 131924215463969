<template>
  <main class="form-wrapper">
    <h1 class="-xltb column-span-4" style="white-space: none">
      Update Lifecycle Details
    </h1>
    <p
      class="-stb column-span-4"
      :style="`color: ${getStage.color_code}; white-space: nowrap;`"
    >
      Stage {{ getStage.stage_value }} - {{ getStage.stage_name }}
    </p>
    <hr class="column-span-4" />
    <div class="column-span-2">
      <p class="-xstb column-span-1">Start Date</p>
      <input type="date" class="-xstb column-span-2" v-model="startDate" />
    </div>
    <div class="column-span-2">
      <p class="-xstb">Deadline</p>
      <input type="date" class="-xstb column-span-2" v-model="deadline" />
    </div>
    <div class="column-span-2">
      <p class="-xstb">End Date</p>
      <input type="date" class="-xstb column-span-2" v-model="endDate" />
    </div>
    <div class="column-span-2">
      <p class="-xstb">Budget</p>
      <div class="relative flex align-center">
        <input
          type="number"
          class="-xstb column-span-2"
          v-model="stageBudget"
        />
        <div class="absolute"><CurrenciesList /></div>
      </div>
    </div>
    <div class="column-span-2">
      <p class="-xstb">Construction Cost</p>
      <div class="relative flex align-center">
        <input
          type="number"
          class="-xstb column-span-2"
          v-model="constructionCost"
        />
        <div class="absolute"><CurrenciesList /></div>
      </div>
    </div>
    <div class="column-span-2">
      <p class="-xstb">Notes</p>
      <div class="width100 flex-column">
        <textarea class="-xstb" maxlength="255" v-model="notes" />
        <p class="-xstr" style="color: var(--gray3)">
          {{ $getRemainingChars(notes, 255) }} characters left
        </p>
      </div>
    </div>
    <hr class="column-span-4" />

    <section class="btns-wrapper">
      <BaseButton
        :state="'primary'"
        :title="'Update Agent'"
        @click="handleClick"
      />
    </section>
  </main>
</template>

<script>
import BaseButton from "../../../../utils/BaseButton.vue";
import CurrenciesList from "../../../../utils/CurrenciesList.vue";
import { update_lifecycle_details } from "../../../../../services/requests/patch/lifecycle";
export default {
  props: ["item"],
  emits: ["closeModal"],
  components: {
    BaseButton,
    CurrenciesList,
  },

  data() {
    return {
      currency: "€",
      startDate: "",
      deadline: "",
      endDate: "",
      stageBudget: "",
      constructionCost: "",
      notes: "",
    };
  },
  created() {
    this.setInitDataProps();
  },
  methods: {
    async handleClick() {
      //prepare for api code here and send and then:
      console.log(this.item);

      const data = {
        life_cycle_details_id: this.item.life_cycle_details_id,
        stage_start_date: this.startDate,
        stage_deadline: this.deadline,
        stage_end_date: this.endDate,
        stage_budget: this.stageBudget,
        stage_notes: this.notes,
        stage_cost: this.constructionCost,
      };

      const res = await update_lifecycle_details(data);
      console.log("RES OF THE UPDATE: ", res);

      // this.$emit("closeModal");
      this.$router.go(0);
    },
    setInitDataProps() {
      if (this.item.stage_start_date)
        this.startDate = this.item.stage_start_date;
      if (this.item.stage_deadline) this.deadline = this.item.stage_deadline;
      if (this.item.stage_end_date) this.endDate = this.item.stage_end_date;
      if (this.item.stage_budget) this.stageBudget = this.item.stage_budget;
      if (this.item.stage_cost) this.constructionCost = this.item.stage_cost;
      if (this.item.stage_notes) this.notes = this.item.stage_notes;
    },
  },
  computed: {
    getStage() {
      return this.item.stage;
    },
  },
};
</script>

<style scoped>
.form-wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-inline: 32px;
  padding-block: 16px;
  gap: 12px;
}
.column-span-4 {
  grid-column: span 4;
  align-self: start;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  width: 100%;
}
.column-span-3 {
  grid-column: span 3;
  align-self: start;
  width: 100%;
}
.column-span-2 {
  grid-column: span 2;
  align-self: start;
  width: 100%;
}

.column-span-1 {
  grid-column: span 1;
  align-self: start;
  width: 100%;
}
.flex-item {
  display: flex;
  gap: 8px;
  /* flex-wrap: wrap; */
}
.flex-column {
  display: flex;
  flex-direction: column;
}
hr {
  border: none;
  border-top: 1px solid var(--gray6);
  margin-block: 8px;
}
/* *** TENTAR GLBOALIZAR *** */
input,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}
input,
textarea {
  width: 100%;
  position: relative;
}
input[type="number"] {
  padding-right: calc(100px + 1rem);
}

.absolute {
  right: 0;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
}
/**EFFECTS **/
input:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
textarea {
  resize: none;
  height: 110px;
}
p {
  user-select: none;
}
.-xltb {
  white-space: nowrap;
}

.btns-wrapper {
  grid-column: 4 / span 1;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  width: 100%;
  position: absolute;
  opacity: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* *** QUERIES *** */
@media screen and (max-width: 599px) {
  .column-span-4 {
    grid-column: span 4;
    align-self: start;
    display: inline;
  }
  .column-span-3 {
    grid-column: span 4;
  }
  .column-span-2 {
    grid-column: span 4;
  }
  .column-span-1 {
    grid-column: span 4;
  }
  .btn-wrapper-step2 {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 454px) {
  .step-number {
    display: none;
  }
  .main-btns-wrapper {
    flex-direction: column-reverse;
  }
  .error-msg {
    white-space: normal;
  }
}
</style>